/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  // General App Settings
  appName: 'Construction Analytics',
  appId: '5efa5b67-64b4-46c7-bbed-d9680ddbe12a',
  production: true,
  debug: false,
  newRelicScriptPath: 'assets/scripts/newRelicStgScript.js',
  gainsightScriptPath: 'assets/scripts/gainsightStgScript.js',
  showAuthDebug: false,
  tosVersion: '2',
  warningBeforeLogoutLengthInMilliseconds: 300000,
  defaultRouterPath: '/reports',

  // Authentication
  // eslint-disable-next-line max-len
  authenticationUrl: 'https://stg-services.trimbleplatform.ninja',
  tidUrl: 'https://stage.id.trimblecloud.com',
  clientId: '54d08a42-08a4-45b4-bf97-d9bcaf9cf04c',
  productId: 'b228eb1a-dd9d-4400-ae4d-951cc2e2206d',
  tidAppName: 'Construction_Analytics_Portal_Stg',
  postLogoutRedirectUri: 'https://stg-analyticsportal.mepdevelopment.net',
  oidcIssuer: 'https://identity-stg.trimble.com',
  oidcRedirectUri: 'https://stg-analyticsportal.mepdevelopment.net/session',
  oidcClientId: 'aKqn1i6xtdq6VOwruLJHp2x5vdUa',
  oidcScope: 'openid',
  sessionUrl: 'https://api-stg-usw2.trimblepaas.com/mep_authorization_stg-v1.0',
  logoutUrl:
    // eslint-disable-next-line max-len
    'https://identity-stg.trimble.com/i/commonauth?commonAuthLogout=true&type=samlsso&sessionDataKey=E294FEF4A64BF7E14940E2964F78E351&commonAuthCallerPath=https%3A%2F%2Fstg-analyticsportal.mepdevelopment.net%2Flogin',

  // Feature override flags
  showContactsFeature: false,

  // Web Service Urls
  reportServiceUrl: 'https://cloud.stage.api.trimblecloud.com/constructionanalytics/ReportService/v1/',
  notificationServiceUrl: 'https://cloud.stage.api.trimblecloud.com/constructionanalytics/NotificationService/v1/',
  userServiceUrl: 'https://cloud.stage.api.trimblecloud.com/constructionanalytics/UserService/v1/',
  customerListUrl: 'https://stg-services.trimbleplatform.ninja/licenses/Customer/list',
  profilesBaseUrl: 'https://cloud.stage.api.trimblecloud.com/tcloud-profiles-stage/1.0',
  // Reports
  workspaceId: 'c4a3c242-21bf-40bc-a4a4-8b84f1fae70b',

  // Version URLs
  portalVersionUrl: 'https://stg-analyticsportal.mepdevelopment.net/version.json',
  notificationVersionUrl: 'https://stg-analyticsnotificationservice.mepdevelopment.net/Service/Health',
  userVersionUrl: 'https://stg-analyticsuserservice.mepdevelopment.net/Service/Health',
  reportsVersionUrl: 'https://stg-analyticsreportservice.mepdevelopment.net/connected/ping',

  //Profile Service Trns
  acceptedConsentTrn: 'trn:profiles:consents:062ccbaf-28de-49ef-9f94-a5c0b5f29e96',
  rejectedConsentTrn: 'trn:profiles:consents:afc35286-9053-4edf-9eb5-678bfd9ec52e',

  // Email Settings
  emailApiUrl: 'https://stg-analyticscoreservice.mepdevelopment.net/Email',
  emailFromAddress: 'noreply@trimble.com',
  emailToAddress: 'analytics-testing-ug@trimble.com',
  supportEmailAddress: 'analytics@trimble.com',

  // 3rd Party Keys
  gainsightTagKey: 'AP-M7YDAYZDRIWI-2-2',
  googleAnalyticsKey: 'G-0YCV9FRF5L',
  logRocketKey: '60xrnx/trimble-analytics-stage',

  // Report IDs for Home Components

  homeReportIds: {
    manufacturer: {
      engagementTrends: '28897cc4-ff49-4c8f-aee0-23d6205e5d33',
      ads: 'c80a56a7-e409-4942-b5e7-2547b906c894',
      downloads: '4b22fbf4-0b96-4259-bec8-71e868845364',
      quotes: '58781ed8-f23e-4320-8b8a-0340e899eb86',
      search: '003e47c4-8ba3-423a-9a7e-6de28d25f799',
      models: 'f10a3ce6-162c-48b7-8ee7-796754594619'
    }
  },

  reportIds: {
    manufacturer: {
      contentHealth: '8095ecc5-0cc9-4812-9cc6-fb8b714ac0bc',
      ads: 'de2bac42-5bb5-4f20-a9d5-84bca31b9cad',
      itemSearch: '3fb53426-20f7-465f-838a-3a8f662137c9',
      downloads: 'f4989b6c-be46-4a92-a25f-da2a63e3f40a',
      estimates: 'cd96219c-4198-4321-b756-f3125c587846',
      quotes: 'ddc69f63-bc24-4dcb-af4b-f0a5783d10f2',
      purchaseRequests: 'f2041910-9c33-4e39-be0d-c5fe6c5bae6a',
      models: '8f617de0-4739-40bd-894c-ad6b8231e7fd'
    },
    contractor: {
      sysque: '487e8ae1-413e-46f8-a93c-9d2bd0a5acd2',
      fabShop: '9ff081fb-f85c-4767-9bd6-2f279025f842',
      accubid: '19e2d5d7-bc5e-4c56-ab1a-808a415d3b51',
      connect2fab: '3ee03f46-24de-4c67-b140-8ea9e349f72b'
    },
    internal: {
      trimbleConstructionContent: '55bcc5d3-1606-4e20-890c-adec04920a0a',
      trimbleComponents: '79dbcd0f-ddb1-48bc-a8ef-dad95f9f2b6f',
      priceHistory: '74c2b069-e0ef-4e44-9c5c-3d2dd6c81a88',
      accountUtility: '7948db61-c093-4508-a657-fcd29e10511b',
      siteAnalytics: '23ea3b9d-827f-49e1-ba8e-6fd819fa5153',
      loginData: '5d6b24d7-4b18-4b97-a1ef-b1f2dd203b01',
      sysqueActivity: '681e1ec8-6bee-4204-9c84-a85cded3c2c3',
      rawContribution: 'cf3bf054-ad7b-4100-9ba5-241fda4a90bc',
      manufacturersGap: 'c96d3c0a-6d9e-4c25-a13e-1b85c4c6ac0d',
      manufacturerSegmentation: '388acf4a-bd33-4b3e-88f0-31733c9e4957',
      qbr: '7d3c8e6f-586d-40c1-b776-a15c61fa1131',
      sellerMap: 'be4f9a92-acdf-42d1-be69-c29195d7d744',
      contractorPrioritization: 'eb76e353-19e1-4b05-b6a7-ef3c5c2c2f58'
    }
  }
};
