import { Injectable } from '@angular/core';
import { ReleaseNote } from '../models/release-note-model';

@Injectable({
  providedIn: 'root'
})
export class ReleaseNotesService {

  public releaseRole = '';

  constructor() { }

  // Returns all release notes
  get allReleaseNotes(): ReleaseNote[] {
    const rn = [
      {
        title: 'Release Notes v4.5',
        img: 'assets/images/need-help/release-notes/v4_5RN.png',
        url: 'assets/pdf/need-help/release-notes/Construction%20Analytics%20v4.5%20Release%20Notes.pdf',
        audience: 'all',
        date: '2/9/2023'
      },
      {
        title: 'Release Notes v4.4',
        img: 'assets/images/need-help/release-notes/v4_4RN.png',
        url: 'assets/pdf/need-help/release-notes/Construction%20Analytics%20v4.4%20Release%20Notes.pdf',
        audience: 'all',
        date: '1/23/2023'
      },
      {
        title: 'Release Notes v3.6',
        img: 'assets/images/need-help/release-notes/v3_6RN.png',
        url: 'assets/pdf/need-help/release-notes/Construction%20Analytics%20v3.6%20Release%20Notes.pdf',
        audience: 'all',
        date: '8/19/2021'
      },{
        title: 'Release Notes v3.5',
        img: 'assets/images/need-help/release-notes/v3_5RN.png',
        url: 'assets/pdf/need-help/release-notes/Construction%20Analytics%20v3.5%20Release%20Notes.pdf',
        audience: 'all',
        date: '8/6/2021'
      },{
        title: 'Release Notes v3.4',
        img: 'assets/images/need-help/release-notes/v3_4RN.png',
        url: 'assets/pdf/need-help/release-notes/Construction%20Analytics%20v3.4%20Release%20Notes.pdf',
        audience: 'all',
        date: '7/22/2021'
      },{
        title: 'Release Notes v3.3',
        img: 'assets/images/need-help/release-notes/v3_3RN.png',
        url: 'assets/pdf/need-help/release-notes/Construction%20Analytics%20v3.3%20Release%20Notes.pdf',
        audience: 'all',
        date: '7/8/2021'
      },{
        title: 'Release Notes v3.2',
        img: 'assets/images/need-help/release-notes/v3_2RN.png',
        url: 'assets/pdf/need-help/release-notes/Construction%20Analytics%20v3.2%20Release%20Notes.pdf',
        audience: 'all',
        date: '6/23/2021'
      },
      {
        title: 'Release Notes v3.1',
        img: 'assets/images/need-help/release-notes/v3_1RN.png',
        url: 'assets/pdf/need-help/release-notes/Construction%20Analytics%20v3.1%20Release%20Notes.pdf',
        audience: 'all',
        date: '6/14/2021'
      },
      {
        title: 'Release Notes v3.0',
        img: 'assets/images/need-help/release-notes/v3_0RN.png',
        url: 'assets/pdf/need-help/release-notes/Construction%20Analytics%20v3.0%20Release%20Notes.pdf',
        audience: 'all',
        date: '5/27/2021'
      },
      {
        title: 'Release Notes v2.13',
        img: 'assets/images/need-help/release-notes/v2_13RN.png',
        url: 'assets/pdf/need-help/release-notes/Construction%20Analytics%20v2.13%20Release%20Notes.pdf',
        audience: 'all',
        date: '4/29/2021'
      },
      {
        title: 'Release Notes v2.11',
        img: 'assets/images/need-help/release-notes/v2_11RN.png',
        url: 'assets/pdf/need-help/release-notes/Construction Analytics v2.11 Release Notes.pdf',
        audience: 'all',
        date: '4/1/2021'
      },
      {
        title: 'Release Notes v2.10',
        img: 'assets/images/need-help/release-notes/v2_10RN.png',
        url: 'assets/pdf/need-help/release-notes/Construction%20Analytics%20v2.10%20Release%20Notes.pdf',
        audience: 'all',
        date: '3/8/2021'
      },
      {
        title: 'Release Notes v2.9',
        img: 'assets/images/need-help/release-notes/v2_9RN.png',
        url: 'assets/pdf/need-help/release-notes/Construction%20Analytics%20v2.9%20Release%20Notes.pdf',
        audience: 'all',
        date: '2/18/2021'
      },
      {
        title: 'Release Notes v2.8',
        img: 'assets/images/need-help/release-notes/v2.8_RN.png',
        url: 'assets/pdf/need-help/release-notes/Construction%20Analytics%20v2.8%20Release%20Notes.pdf',
        audience: 'all',
        date: '2/05/2021'
      },
      {
        title: 'Release Notes v2.6',
        img: 'assets/images/need-help/release-notes/v2_6RN.png',
        url: 'assets/pdf/need-help/release-notes/Construction%20Analytics%20v2.6%20Release%20Notes.pdf',
        audience: 'all',
        date: '1/04/2021'
      },
      {
        title: 'Release Notes v2.5',
        img: 'assets/images/need-help/release-notes/v2_5RN.png',
        url: 'assets/pdf/need-help/release-notes/Construction%20Analytics%20v2.5%20Release%20Notes.pdf',
        audience: 'all',
        date: '12/11/2020'
      },
      {
        title: 'Release Notes v2.4',
        img: 'assets/images/need-help/release-notes/v2_4RN.png',
        url: 'assets/pdf/need-help/release-notes/Construction%20Analytics%20v2.4%20Release%20Notes.pdf',
        audience: 'all',
        date: '11/20/2020'
      },
      {
        title: 'Release Notes v2.3',
        img: 'assets/images/need-help/release-notes/v2_3RN.PNG',
        url: 'assets/pdf/need-help/release-notes/Construction%20Analytics%20v2.3.2%20Release%20Notes.pdf',
        audience: 'all',
        date: '11/12/2020'
      },
      {
        title: 'Release Notes v2.2',
        img: 'assets/images/need-help/release-notes/v2_2RN.PNG',
        url: 'assets/pdf/need-help/release-notes/Construction%20Analytics%20v2.2%20Release%20Notes.pdf',
        audience: 'all',
        date: '10/15/2020'
      },
      {
        title: 'Release Notes v2.1',
        img: 'assets/images/need-help/release-notes/v2_1RN.PNG',
        url: 'assets/pdf/need-help/release-notes/Construction Analytics v2.1 Release Notes.pdf',
        audience: 'all',
        date: '10/1/2020'
      },
      {
        title: 'Release Notes v2.0',
        img: 'assets/images/need-help/release-notes/v2_0RN.PNG',
        url: 'assets/pdf/need-help/release-notes/Construction Analytics v2.0 Release Notes.pdf',
        audience: 'all',
        date: '9/17/2020'
      },
      {
        title: 'Release Notes v1.9',
        img: 'assets/images/need-help/release-notes/v1_9RN.PNG',
        url: 'assets/pdf/need-help/release-notes/Manufacturer Construction Analytics v1.9 Release Notes.pdf',
        audience: 'all',
        date: '8/20/2020'
      },
      {
        title: 'Release Notes v1.8',
        img: 'assets/images/need-help/release-notes/v1_8RN.PNG',
        url: 'assets/pdf/need-help/release-notes/Construction Analytics v1.8 Release Notes.pdf',
        audience: 'all',
        date: '8/6/2020'
      },
      {
        title: 'Release Notes v1.6',
        img: 'assets/images/need-help/release-notes/v1_6RN.PNG',
        url: 'assets/pdf/need-help/release-notes/Construction Analytics v1.6 Release Notes July 2020.pdf',
        audience: 'all',
        date: '7/14/2020'
      }
    ];
    return rn;
  }

  // Returns the first release note from all release notes
  get currentReleaseNotes(): ReleaseNote {
    return this.allReleaseNotes[0];
  }

  // Returns all release notes except the first one
  get historicalReleaseNotes(): ReleaseNote[] {
    const arr = this.allReleaseNotes;
    arr.splice(0, 1);
    return arr;
  }

  get allReleaseNotesContractor(): ReleaseNote[] {
    const rn = [
    ];
    return rn;
  }

  // Returns the first release note from all release notes
  get currentReleaseNotesContractor(): ReleaseNote {
    return this.allReleaseNotesContractor[0];
  }

  // Returns all release notes except the first one
  get historicalReleaseNotesContractor(): ReleaseNote[] {
    const arr = this.allReleaseNotesContractor;
    arr.splice(0, 1);
    return arr;
  }

}
